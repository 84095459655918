import React, { useEffect, useState } from "react";
import { Interactive } from "@react-three/xr";
import { useFrame, useThree } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import { GetMessages, SaveMessage } from "../../../utils/messagesApi";
import { getChildWorldPosition } from "../../../utils/utils";
import Box from "../Box";

export default function NewMessage(props) {
  const { newMsg, setMessages} = props;

  const { camera } = useThree();

  const [text, setText] = useState({
    message: newMsg,
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  });

  const [position, setPosition] = useState([0, 0, -2]);
  const [rotation, setRotation] = useState([0, 0, 0]);

  useEffect(() => {
    setText({
      message: newMsg,
      position: position,
      rotation: rotation,
    });
  }, [newMsg]);

  useFrame(() => {
    if (camera) {
      const childWorldPosition = getChildWorldPosition(
        [camera.position.x, camera.position.y, camera.position.z],
        [camera.rotation._x, camera.rotation._y, camera.rotation._z],
        [0, 0, -1]
      );

      //set position of button to camera position
      setPosition([
        childWorldPosition.x,
        childWorldPosition.y,
        childWorldPosition.z,
      ]);
      //set rotation of button to camera rotation
      setRotation([camera.rotation._x, camera.rotation._y, camera.rotation._z]);
    }
  });

  function onSelect() {
    let location =
      position[0] +
      "," +
      position[1] +
      "," +
      position[2] +
      "," +
      rotation[0] +
      "," +
      rotation[1] +
      "," +
      rotation[2];
    SaveMessage(text.message, location);
    setText(null);

    async function load() {
      const msg = await GetMessages(setMessages);
    }

    load().then(() => {
      console.log("Messages updated");
    });
  }

  return (
    <>
      {text != null && (
        <group position={position} rotation={rotation}>
          <group position={[0, 0, 0]}>
            <Text
              fontSize={0.2}
              color="#ff0000"
              anchorX="center"
              anchorY="middle"
              fillOpacity={1}
              transparent={true}
            >
              {text.message}
            </Text>
            <Interactive onSelect={onSelect}>
              <Box
                color="chocolate"
                scale={[0.5, 0.5, 0.5]}
                size={[1.2, 0.3, 0.1]}
                position={[0, -0.2, 0]}
                {...props}
              >
                <Text
                  position={[0, 0, 0.2]}
                  fontSize={0.15}
                  color="#000"
                  anchorX="center"
                  anchorY="middle"
                >
                  PLACE WORD
                </Text>
              </Box>
            </Interactive>
          </group>
        </group>
      )}
    </>
  );
}
