import { Title, Text, Container, List, Blockquote } from "@mantine/core";
import { useState } from "react";

export default function Intro() {
  const [lang, setLang] = useState("eng");

  return (
    <Container className="intro-container">
      <div className="languages">
        <div
          className={lang == "eng" ? "sel active" : "sel"}
          onClick={() => {
            setLang("eng");
          }}
        >
          {" "}
          &nbsp;ENG&nbsp;{" "}
        </div>
        <div
          className={lang == "eng" ? "sel" : "sel active"}
          onClick={() => {
            setLang("cns");
          }}
        >
          {" "}
          &nbsp;CN&nbsp;{" "}
        </div>
      </div>
      {lang == "eng" ? (
        <>
          <Title order={3}>FORGOTTEN WORDS</Title>
          <Text size="sm">
            Words enable us to understand the world, create and communicate
            ideas. Children use it to ask for help, governments use it to shape
            our thinking — words do not stop being invented, more often reformed
            or banned. You can be prosecuted for using the word "Peace" in
            Russia's wartime state. With so strong power, little is said about
            its responsibilities.
          </Text>
          <br />
          <Text size="sm">
            Every year people make a list of words reflecting the unique
            challenges and ideas humanity meets. 2022 marked in the history with
            "Gaslighting", "Goblin mode" and "Permacrisis". Most of us would
            like to add "War" and "Lockdown" to this list.
          </Text>
          <br />
          <Blockquote>
            What words are you afraid will disappear or be forgotten?
          </Blockquote>
          <br />
          <Title order={5}>Instruction</Title>
          <List size="sm">
            <List.Item>
              If you scan through Wechat, please press the top right corner
              botton and select "open in brower"
            </List.Item>
            <List.Item>
              For IOS devices you need to open it in the{" "}
              <a
                href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
                target="_blank"
              >
                WebXR viewer
              </a>
              Best experience on Android devices. iOS devices may have some
              issues.
            </List.Item>
            <List.Item>
              Be careful not to run into any physical objects while exploring,
              they still exist in the physical plane.
            </List.Item>
            <List.Item>
              Now, follow us and immerse yourself in this unknown journey of
              drifting.
            </List.Item>
          </List>
        </>
      ) : (
        <>
          <Title order={3}>遗忘的词汇</Title>
          <Text size="sm">
            词语使我们了解世界，创造并交流想法。孩童用它来寻求帮助，政府用它来捏造我们的思维——词语并未止步于被发明创造，而更经常被改造或禁止使用，你会因在俄罗斯战时状态使用词语“和平”而受到法律起诉。在如此强大的力量下，却鲜少有人提及它所应该承担的责任。
          </Text>
          <br />
          <Text size="sm">
            每年人们都会列出一个反映全人类面临的独特挑战和想法的词汇清单，2022年以“煤气灯效应”、“哥布林模式”和“永久危机”载入史册，而不少人则希望将“战争”和“封控”加入列表中。
          </Text>
          <br />
          <Blockquote>你害怕哪些词语将会消失或被遗忘？</Blockquote>
          <br />
          <Title order={5}>Instruction</Title>
          <List size="sm">
            <List.Item>
              如果你是通过微信扫码，请点击右上角的三个点，在上拉菜单中选择“在浏览器中打开”
            </List.Item>
            <List.Item>
              IOS系统需要从这里打开：
              <a
                href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
                target="_blank"
              >
                WebXR viewer
              </a>
              最佳体验在安卓系统，IOS系统可能会有一些问题。
            </List.Item>
            <List.Item>
              行走时请注意不要碰到周围的物件，它们依然是真实存在的。
            </List.Item>
            <List.Item>现在，请随着我们一起浸入未知的「漂移」之旅。</List.Item>
          </List>
        </>
      )}
    </Container>
  );
}
