import axios from "axios";

const apiUrl = "https://api.sokaris.link";
const apiActive = true;

export function GetMessages(setMessages) {
  let messages = [];

  if (apiActive) {
    try {
      axios.get(`${apiUrl}/api/fw-lists`).then((res) => {
        const messagesData = res.data.data;

        if (messagesData.length > 0) {
          // loop over all array

          for (let i = 0; i < messagesData.length; i++) {
            let msg = messagesData[i].attributes.text;
            let pos = messagesData[i].attributes.location.split(",");
            const message = {
              message: msg,
              position: [pos[0], pos[1], pos[2]],
              rotation: [pos[3], pos[4], pos[5]],
            };
            messages.push(message);
          }
        }
        setMessages(messages);
      });
    } catch (e) {
      console.log(e);
    }
  } else {
    console.log("API disabled");
  }
}

export function SaveMessage(message, position) {
  try {
    const response = axios
      .post(`${apiUrl}/api/fw-lists/`, {
        data: {
          text: message,
          location: position,
        },
      })
      .then((res) => {
        // console.log(res);
        console.log("Success");
      });

    // toast.success("Settings saved", { closeButton: true, autoClose: 15000 });
  } catch (e) {
    console.log(e);
    // toast.error("No image selected", { closeButton: true, autoClose: 15000 });
  }
}
