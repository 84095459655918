import { Controllers, Hands } from "@react-three/xr";
import TextMessages from "../Texts";
import NewMessage from "../NewMessage";

export default function XRComp(props) {
  const { messages, setMessages, newMsg } = props;

  return (
    <>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <group>
        {newMsg != null && (
          <NewMessage newMsg={newMsg} setMessages={setMessages} />
        )}
        <TextMessages messages={messages} />
      </group>
      <Controllers />
      <Hands />
    </>
  );
}
