import "./App.css";
import XRScene from "./component/AR/Scene";

function App() {
  return (
    <div className="App">
      <XRScene />
    </div>
  );
}

export default App;
