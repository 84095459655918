import { XR, XRButton } from "@react-three/xr";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react";

import XRComp from "../XRComp";
import Intro from "../../Intro";
import AddMessage from "../../UI/AddMessage";
import { GetMessages } from "../../../utils/messagesApi";
import { Blockquote, Button, Container } from "@mantine/core";

function Loading() {
  return (
    <div className="loading">
      <h2>LOADING</h2>
      <div>
        Please open the link in your browser. For IOS devices you need to open
        it in the
        <a
          href="https://apps.apple.com/us/app/webxr-viewer/id1295998056"
          target="_blank"
        >
          WebXR viewer
        </a>
      </div>
      <div className="halfOpacity">
        <img className="d-block" src="/loading.svg" alt="Loading" />
      </div>
    </div>
  );
}

export default function XRScene() {
  const [ready, setReady] = useState(false);
  const [state, setState] = useState(0);

  const [messages, setMessages] = useState(null);
  const [newMsg, setNewMsg] = useState(null);

  useEffect(() => {
    async function load() {
      const msg = await GetMessages(setMessages);
    }
    load().then((res) => {
      console.log("Messages updated");
    });
  }, []);

  const XRbutton = useRef();

  function enterAR() {
    if (XRbutton.current) {
      if (XRbutton.current.innerText != "AR unsupported") {
        setReady(true);
      }
    }
  }

  return (
    <>
      {state === 0 && (
        <>
          <Intro />
          <Button
            className="btn-continue"
            onClick={() => {
              setState(1);
            }}
          >
            START / 开始
          </Button>
        </>
      )}
      {state === 1 && newMsg === null && (
        <>
          <AddMessage setMessages={setNewMsg} setState={setState} />
        </>
      )}
      {state === 2 && (
        <div id="AR-canvas">
          <Suspense fallback={<Loading />}>
            <Container>
              <Blockquote>
                Please point your phone on the poster and enter experience
                <br />
                请将手机对准海报并进入体验
              </Blockquote>
            </Container>
            <XRButton
              mode="AR"
              onClick={enterAR}
              className="ar-button"
              ref={XRbutton}
            />
            <Canvas id="canvas">
              <XR referenceSpace="local-floor" enterOnly={true}>
                <XRComp
                  ready={ready}
                  messages={messages}
                  setMessages={setMessages}
                  newMsg={newMsg}
                />
              </XR>
            </Canvas>
          </Suspense>
        </div>
      )}
    </>
  );
}
