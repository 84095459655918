import Text3D from "../Text3D";

export default function TextMessages(props) {
  const { messages } = props;

  return (
    <>
      {messages?.map((message, i) => (
        <Text3D key={`msg_${i}`} message={message} color="#ff5511" />
      ))}
    </>
  );
}
