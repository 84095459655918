import { Text } from "@react-three/drei";
import { useEffect, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { getDistance, mapRange } from "../../../utils/utils";
import { Interactive } from "@react-three/xr";

const max_distance = 2;
const min_distance = 0.5;
const opacityRange = [0, 1];

export default function Text3D(props) {
  const { message, scale, color = "#000000" } = props;

  const { camera } = useThree();

  const [opacity, setOpacity] = useState(1);
  const [distance, setDistance] = useState(0);
  const [reveal, setReveal] = useState(false);
  const [msg, setMsg] = useState("");
  const [encoded, setEncoded] = useState("***");
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
    z: 0,
  });

  useEffect(() => {
    if (message.position) {
      setPos({
        x: message.position[0],
        y: message.position[1],
        z: message.position[2],
      });
    }

    if (message.message) {
      setMsg(message.message);
      setEncoded(message.message.replace(/./g, "*"));
    }
  }, [message]);

  useFrame(() => {
    if (camera) {
      setDistance(getDistance(camera.position, pos));
      setOpacity(
        opacityRange[1] -
          mapRange(
            distance,
            min_distance,
            max_distance,
            opacityRange[0],
            opacityRange[1]
          )
      );
    }
  });

  return (
    <group
      position={message.position}
      rotation={message.rotation}
      scale={scale}
    >
      <Interactive  onSelect={()=>{setReveal(true)}}>
        <Text
          fontSize={0.1}
          color={color}
          anchorX="center"
          anchorY="middle"
          fillOpacity={opacity}
          transparent={true}
        >
          {reveal ? message.message : encoded}
        </Text>
      </Interactive>
    </group>
  );
}
