import { TextInput, Button, Blockquote, Container } from "@mantine/core";
import { useState } from "react";


export default function AddMessage(props) {
  const { messages, setMessages, setState } = props;

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  function SendMessage() {
    if (message != "") {
      setMessages(message);
      setState(2);
    } else {
      setError("Cannot be empty / 不能為空");
    }
  }

  return (
    <div id="addMessage">
      <Blockquote>
        What words are you afraid will disappear or be forgotten? <br />
        你害怕哪些词语将会消失或被遗忘？
      </Blockquote>
      <Container>
        <TextInput
          label="Your word / 你的词语"
          description="For now only english letters are supported / 目前仅支持英文"
          value={message}
          onChange={(val) => {
            setMessage(val.currentTarget.value);
          }}
          error={error}
        />
        <Button className="btn-pass" onClick={SendMessage}>
          Add word / 添加词语
        </Button>
        <Button
          className="btn-pass"
          onClick={() => {
            setState(2);
          }}
        >
          Just see / 只看看
        </Button>
      </Container>
    </div>
  );
}
