//function to get distance between two vectors
import * as THREE from "three";

export function getDistance(v1, v2) {
  return Math.sqrt(
    Math.pow(v1.x - v2.x, 2) +
      Math.pow(v1.y - v2.y, 2) +
      Math.pow(v1.z - v2.z, 2)
  );
}

//map one range to another with limit to the desired range
export function mapRange(value, low1, high1, low2, high2) {
  return Math.max(
    Math.min(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1), high2),
    low2
  );
}

//map one range to another without limit to the desired range
export function mapRangeUnlimited(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

// generate random int in range
export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

//generate random float in range
export function randomFloatFromInterval(min, max) {
  return Math.random() * (max - min) + min;
}

export function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

// random position
export function RandPosition(minx, maxx, miny, maxy, minz, maxz) {
  return (
    randomFloatFromInterval(minx, maxx) +
    "," +
    randomFloatFromInterval(miny, maxy) +
    "," +
    randomFloatFromInterval(minz, maxz)
  );
}

export function RandomLocation(minx, maxx, miny, maxy, minz, maxz) {
  return (
    RandPosition(minx, maxx, miny, maxy, minz, maxz) +
    "," +
    RandPosition(0, 0, -Math.PI, Math.PI, 0, 0)
  );
}

// get position of the object 2 in front of object 1 when known position of object 1 and its rotation, position and rotation arrays
export function GetPosition(position1, rotation, distance) {
  let position2 = [0, 0, 0];
  position2[0] = position1.x + Math.cos(rotation._y) * distance;
  position2[1] = position1.y + Math.sin(rotation._y) * distance;
  position2[2] = position1.z + Math.sin(rotation._x) * distance;
  return position2;
}

export function getChildWorldPosition(
  parentPosition,
  parentRotation,
  childDistance
) {
  // Convert the parent position array to a 3D vector:
  const parentPositionVector = toVector3(parentPosition);

  // Convert the parent rotation array to a quaternion:
  const parentRotationQuaternion = toQuaternion(parentRotation);

  // Convert the child distance array to a 3D vector:
  const childDistanceVector = toVector3(childDistance);

  // Calculate the world position of the child using the parent position and rotation and the child distance:
  const childWorldPosition = addVectors(
    parentPositionVector,
    rotateVector(childDistanceVector, parentRotationQuaternion)
  );

  return childWorldPosition;
}

function toVector3(array) {
  return { x: array[0], y: array[1], z: array[2] };
}

function toQuaternion(array) {
  return {
    x:
      Math.sin(array[0] / 2) * Math.cos(array[1] / 2) * Math.cos(array[2] / 2) -
      Math.cos(array[0] / 2) * Math.sin(array[1] / 2) * Math.sin(array[2] / 2),
    y:
      Math.cos(array[0] / 2) * Math.sin(array[1] / 2) * Math.cos(array[2] / 2) +
      Math.sin(array[0] / 2) * Math.cos(array[1] / 2) * Math.sin(array[2] / 2),
    z:
      Math.cos(array[0] / 2) * Math.cos(array[1] / 2) * Math.sin(array[2] / 2) -
      Math.sin(array[0] / 2) * Math.sin(array[1] / 2) * Math.cos(array[2] / 2),
    w:
      Math.cos(array[0] / 2) * Math.cos(array[1] / 2) * Math.cos(array[2] / 2) +
      Math.sin(array[0] / 2) * Math.sin(array[1] / 2) * Math.sin(array[2] / 2),
  };
}

function rotateVector(vector, quaternion) {
  const x = quaternion.x * 2;
  const y = quaternion.y * 2;
  const z = quaternion.z * 2;
  const xx = quaternion.x * x;
  const yy = quaternion.y * y;
  const zz = quaternion.z * z;
  const xy = quaternion.x * y;
  const xz = quaternion.x * z;
  const yz = quaternion.y * z;
  const wx = quaternion.w * x;
  const wy = quaternion.w * y;
  const wz = quaternion.w * z;

  return {
    x: (1 - (yy + zz)) * vector.x + (xy - wz) * vector.y + (xz + wy) * vector.z,
    y: (xy + wz) * vector.x + (1 - (xx + zz)) * vector.y + (yz - wx) * vector.z,
    z: (xz - wy) * vector.x + (yz + wx) * vector.y + (1 - (xx + yy)) * vector.z,
  };
}

function addVectors(vector1, vector2) {
  return {
    x: vector1.x + vector2.x,
    y: vector1.y + vector2.y,
    z: vector1.z + vector2.z,
  };
}